<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="primary--text">{{$t('menu.criminalCases')}}</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-btn class="ma-2" outlined @click="$router.push('/criminal-cases/add')">
                            <v-icon left>{{icons.mdiPencil}}</v-icon>{{$t('button.newCase')}}</v-btn>
                        <v-btn class="ma-2" outlined @click="$router.push('/police-cases')">
                            <v-icon left>{{icons.mdiViewListOutline}}</v-icon> {{$t('menu.caseFromPolice')}}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field :prepend-inner-icon="icons.mdiMagnify" label="Search" single-line outlined dense v-model="search"></v-text-field>
                    </v-col>
                </v-row>
                <v-data-table :headers="headers" :items="caseList">
                    <template #[`item.user`]="{item}">
                        <div class="d-flex flex-column">
                            {{item.user.username}}
                        </div>
                    </template>
                    <template #[`item.caseNo`]="{item}">
                        <div class="text-left">
                            <v-chip color="primary" small rounded outlined @click="showParties(item)">
                                {{item.caseNo}}/{{item.caseRegisDate}}
                            </v-chip>
                        </div>

                    </template>
                    <template #[`item.court`]="{item}">
                        <div class="d-flex flex-column">
                            {{item.court.nameKh}}
                        </div>
                    </template>
                    <template #[`item.actions`]="{item}">
                        <v-btn class="btn" small rounded outlined @click="goToCaseProgress(item)">
                            {{$t('button.progress')}}
                        </v-btn>
                    </template>
                </v-data-table>
                <v-dialog transition="dialog-bottom-transition" content-class="custom-dialog" position-relative="right" v-model="dialog" fullscreen>
                    <v-card class="absolute" height="100%">
                        <v-toolbar>
                            <v-btn text fab dark large color="primary" @click="dialog=false">
                                <v-icon>{{icons.mdiClose}}</v-icon>
                            </v-btn>
                            <v-toolbar-title>
                                <b>{{$t('case.caseInfo')}}</b>
                            </v-toolbar-title>
                        </v-toolbar>
                        <div class="scrollable-content">
                            <v-row>
                                <v-col cols="12">
                                    <v-card-text class="mt-5">
                                        <v-simple-table>
                                            <tr>
                                                <th>លេខសំណុំរឿង</th>
                                                <th>តុលាការ</th>
                                                <th>កាលបរិច្ឆេទសំណុំរឿង</th>
                                                <th>កាលបរិច្ឆេទចុះ</th>
                                            </tr>
                                            <tr style="text-align:center">
                                                <td>{{criminalCase.caseNo}}</td>
                                                <td v-if="criminalCase.court">{{criminalCase.court.nameKh}}</td>
                                                <td>{{criminalCase.caseOrigDate}}</td>
                                                <td>{{criminalCase.caseRegisDate}}</td>
                                            </tr>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-card-text>
                                        <v-data-table :headers="partyHeader" :items="partyList">
                                            <template #[`item.no`]="{item}">
                                                <div class="d-flex flex-column">
                                                    {{partyList.indexOf(item)+1}}
                                                </div>
                                            </template>
                                            <template #[`item.gender`]="{item}">
                                                <div class="d-flex flex-column">
                                                    <span v-if="item.gender">{{$t('text.female')}}</span>
                                                    <span v-else>{{$t('text.male')}}</span>
                                                </div>
                                            </template>
                                            <template #[`item.partyRole`]="{item}">
                                                <div class="d-flex flex-column">
                                                    {{item.partyRole.roleNameKh}}
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-card-text>
                                        <v-alert text color="info" style="border: 2px dotted black">
                                            <h3 class="text-h5">ការចោទប្រកាន់ជាបឋម</h3>
                                            <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
                                            <v-row align="center" no-gutters>
                                                <v-col class="grow">
                                                    <p v-for="(item,index) in criminalCharges" :key="index">
                                                        {{index+1}}. {{item.caseCrimeType.crimeTypeNameKh}}
                                                    </p>

                                                </v-col>
                                                <v-spacer></v-spacer>
                                                <v-col class="shrink">
                                                    <v-btn color="primary" outlined @click="editCase(selectedCaseId)">
                                                        <v-icon left>{{icons.mdiReceiptTextEdit}}</v-icon> កែប្រែ
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-expansion-panels multiple>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <v-card-title>
                                                <span class="primary--text">{{$t('case.prosPhase')}}</span>
                                            </v-card-title>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div v-if="appointmentList.length > 0">
                                                <h3>ការចាត់តាំងអយ្យការ</h3>
                                                <v-simple-table>
                                                    <tr>
                                                        <th>លើក</th>
                                                        <th>លេខលិខិត</th>
                                                        <th>កាលបរិច្ឆេទ</th>
                                                        <th>តំណាងអយ្យការ</th>
                                                        <th>ក្រឡាបញ្ជី</th>
                                                        <th>បញ្ចូលដោយ</th>
                                                    </tr>
                                                    <tr v-for="(item,index) in appointmentList" :key="item.id" style="text-align:center">
                                                        <td>{{index+1}}</td>
                                                        <td>{{item.letterNo}}</td>
                                                        <td>{{item.appointmentDate}}</td>
                                                        <td>{{item.prosecutorName}}</td>
                                                        <td>{{item.clerkName}}</td>
                                                        <td>{{item.user.username}}</td>
                                                    </tr>
                                                </v-simple-table>
                                            </div>
                                            <br />
                                            <div v-if="warrantList.length > 0">
                                                <h3>ការកោះហៅ</h3>
                                                <v-simple-table>
                                                    <tr>
                                                        <th>លើក</th>
                                                        <th>លេខដីកាកោះ</th>
                                                        <th>កាលបរិច្ឆេទ</th>
                                                        <th>ប្រភេទដីកា</th>
                                                        <th>តំណាងអយ្យការ</th>
                                                        <th>ក្រឡាបញ្ជី</th>
                                                        <th>ជនទាក់ទិន</th>
                                                    </tr>
                                                    <tr v-for="(item,index) in warrantList" :key="item.id" style="text-align:center">
                                                        <td>{{index+1}}</td>
                                                        <td>{{item.warrantLetterNo}}</td>
                                                        <td>{{item.warrantDate}}</td>
                                                        <td>{{item.letterType.letterNameKh}}</td>
                                                        <td>{{item.prosecutorName}}</td>
                                                        <td>{{item.criminalData.user.username}}</td>
                                                        <td>
                                                            <v-chip v-if="item.parties" v-for="each in item.parties" :key="each.id">
                                                                {{each.id}} {{each.nameKh}}
                                                            </v-chip>
                                                        </td>
                                                    </tr>
                                                </v-simple-table>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                        </div>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import {
  mdiCalendarAlert,
  mdiPencil,
  mdiClose,
  mdiViewListOutline,
  mdiReceiptTextEdit
} from '@mdi/js'
import CaseService from '@/services/service.case'

export default {
  name: 'criminal-cases',
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPencil,
        mdiClose,
        mdiViewListOutline,
        mdiReceiptTextEdit
      },
      requestDateMenu: false,
      search: '',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'លេខសំណុំរឿង', value: 'caseNo' },
        { text: 'កាលបរិច្ឆេទចុះសំណុំរឿង', value: 'caseRegisDate' },
        { text: 'កាលបរិច្ឆេទចុះក្នុងប្រព័ន្ធ', value: 'caseOrigDate' },
        { text: 'អ្នកបញ្ចូល', value: 'user' },
        { text: 'ឈ្មោះតុលាការ ', value: 'court' },
        { text: 'ACTION', value: 'actions', sortable: false }
      ],
      partyHeader: [
        { text: 'NO', value: 'no' },
        { text: 'ឈ្មោះ', value: 'nameKh' },
        { text: 'ភេទ', value: 'gender' },
        { text: 'អាយុ', value: 'age' },
        { text: 'Party Role', value: 'partyRole' }
      ],
      caseList: [],
      partyList: [],
      dialog: false,
      selectedCaseId: '',
      criminalCharges: [],
      appointmentList: [],
      warrantList: [],
      criminalCase: {}
    }
  },
  created() {
    CaseService.getCaseAll().then(response => {
      this.caseList = response.data
    })
  },
  methods: {
    goToCaseProgress(item) {
      this.selectedCaseId = item.id
      this.$router.push({
        name: 'criminal-cases-progress',
        params: { action: 'edit', id: this.selectedCaseId }
      })
    },
    async showParties(item) {
      this.criminalCase = item
      this.selectedCaseId = item.id
      await CaseService.getPartyByCaseId(this.selectedCaseId).then(response => {
        this.partyList = response.data
      })

      await CaseService.getChargeByCaseId(this.selectedCaseId).then(
        response => {
          this.criminalCharges = response.data
        }
      )

      await CaseService.getAppointmentByCaseId(this.selectedCaseId).then(
        response => {
          this.appointmentList = response.data
        }
      )

      await CaseService.getWarrantByCaseId(this.selectedCaseId).then(
        response => {
          this.warrantList = response.data
        }
      )

      this.dialog = true
    },
    editCase(id) {
      // alert(id);
      this.$router.push({
        name: 'criminal-cases-edit',
        params: { action: 'edit', id: id }
      })
    }
  }
}
</script>
<style>
.custom-dialog {
  position: absolute;
  right: 0px;
  margin: 0px;
  /* margin: 0; */
  height: 100%;
}
.absolute {
  position: absolute;
}
.scrollable-content {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}
</style>
